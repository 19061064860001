import { useState, useEffect } from 'react';
import {
  Flex,
  Button,
  Text,
  Box,
  useColorMode,
  useColorModeValue,
  IconButton,
  HStack,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Image,
} from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/media-query';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import Link from 'next/link';
import { generatePath } from '../../lib/generatePath';
import fetchJson from '../../lib/fetchJson';

const PREFIX = process.env.NODE_ENV === 'production' ? 'https' : 'http';
const DOMAIN =
  process.env.NODE_ENV === 'production'
    ? 'com'
    : process.env.NEXT_PUBLIC_DEV_DOMAIN;

const NavBar = () => {
  const [display, changeDisplay] = useState('none');
  const [url, setUrl] = useState(`${PREFIX}://app.anderspink.${DOMAIN}`);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { colorMode } = useColorMode();
  const isDark = colorMode == 'dark';

  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');

  const [isLessThan1224] = useMediaQuery('(max-width: 1224px)');

  const background = useColorModeValue('white', 'black.900');

  const menuBackground = useColorModeValue('white', '#202124');

  useEffect(() => {
    (async () => {
      const res: any = await fetchJson(`/api/checkAuth`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.redirect.user) {
        setUrl(res.redirect.destination);
        setIsLoggedIn(true);
      } else {
        setUrl(res.redirect.destination);
        setIsLoggedIn(false);
      }
    })();
  }, []);

  return (
    <Flex
      w="100%"
      boxShadow="lg"
      backgroundColor={background}
      justifyContent="space-between"
      alignItems="center"
      px={isLargerThan1024 ? 10 : 0}
    >
      <Link href={generatePath('/')} style={{ textDecoration: 'none' }}>
        <Image
          src={isDark ? '/ap-logo-white.png' : '/ap-logo-pink.png'}
          alt="logo"
          width={50}
          height={50}
          style={{ margin: 5 }}
        />
      </Link>
      <Flex alignItems="center">
        <Flex display={isLargerThan1024 ? 'flex' : 'none'} p={5} w="100%">
          <HStack alignItems="center" spacing={5}>
            <Menu isLazy>
              <MenuButton
                background="transparent"
                fontSize={18}
                fontWeight="400"
                _hover={{
                  background: 'transparent',
                  color: '#cf0032',
                }}
                _active={{ background: 'transparent' }}
                as={Button}
                rightIcon={<FiChevronDown />}
              >
                Web Curation
              </MenuButton>
              <MenuList background={menuBackground}>
                <MenuItem>
                  <Link
                    href={generatePath('/wc/enterprise')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      For Enterprise
                    </Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={generatePath('/wc/platforms')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      For Platforms
                    </Text>
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            <Link
              href={generatePath('/content-skills-mapper')}
              style={{ textDecoration: 'none' }}
            >
              <Text
                fontSize={18}
                fontWeight="400"
                _hover={{ color: '#cf0032' }}
                style={{ textDecoration: 'none' }}
                cursor="pointer"
              >
                Content to Skills Mapper
              </Text>
            </Link>
            <Menu isLazy>
              <MenuButton
                background="transparent"
                fontSize={18}
                fontWeight="400"
                _hover={{
                  background: 'transparent',
                  color: '#cf0032',
                }}
                _active={{ background: 'transparent' }}
                as={Button}
                rightIcon={<FiChevronDown />}
              >
                Complete Curation
              </MenuButton>
              <MenuList background={menuBackground}>
                <MenuItem>
                  <Link
                    href={generatePath('/cc/enterprise')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      For Enterprise
                    </Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={generatePath('/cc/platforms')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      For Platforms
                    </Text>
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu isLazy>
              <MenuButton
                background="transparent"
                fontSize={18}
                fontWeight="400"
                _hover={{
                  background: 'transparent',
                  color: '#cf0032',
                }}
                _active={{ background: 'transparent' }}
                as={Button}
                rightIcon={<FiChevronDown />}
              >
                More
              </MenuButton>
              <MenuList background={menuBackground}>
                <MenuItem>
                  <Link
                    href={generatePath('/client-success-stories')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      Success Stories
                    </Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={generatePath('/blog')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      Blog
                    </Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    href={generatePath('/contact')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      Contact
                    </Text>
                  </Link>
                </MenuItem>
                <MenuItem display={!isLessThan1224 ? 'none' : 'flex'}>
                  <Link
                    href={generatePath('/contact')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                      style={{ textDecoration: 'none' }}
                      cursor="pointer"
                    >
                      Book a consultation
                    </Text>
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>

            <HStack>
              <Link
                href={generatePath('/contact')}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  background="#cf0032"
                  color="white"
                  rounded={3}
                  _hover={{
                    color: 'white',
                    opacity: 0.7,
                  }}
                  display={isLessThan1224 ? 'none' : 'flex'}
                >
                  <Text fontSize={18} fontWeight="400">
                    Book a consultation
                  </Text>
                </Button>
              </Link>
              <Link href={url} style={{ textDecoration: 'none' }}>
                <Button
                  background="white"
                  color="#cf0032"
                  borderWidth={1}
                  borderColor="#cf0032"
                  rounded={3}
                  _hover={{ color: 'black' }}
                >
                  <Text fontSize={18} fontWeight="400">
                    {isLoggedIn ? 'Go to Apps' : 'Log in'}
                  </Text>
                </Button>
              </Link>
            </HStack>
          </HStack>
        </Flex>
        <IconButton
          aria-label="Open Menu"
          size="md"
          mr={2}
          icon={<GiHamburgerMenu />}
          display={isLargerThan1024 ? 'none' : 'flex'}
          onClick={() => changeDisplay('flex')}
        />
        {/* <IconButton
          aria-label="toggle"
          mr={5}
          icon={isDark ? <FaSun /> : <FaMoon />}
          isRound={true}
          onClick={toggleColorMode}
        /> */}
      </Flex>

      <Flex
        w="100vw"
        backgroundColor={menuBackground}
        zIndex={20}
        h="100vh"
        pos="fixed"
        top={0}
        left={0}
        overflowY="auto"
        flexDir="column"
        display={display}
      >
        <Flex justifyContent="flex-end" m={4}>
          <IconButton
            mt={2}
            mr={2}
            aria-label="Close Menu"
            size="md"
            icon={<AiOutlineClose />}
            onClick={() => changeDisplay('none')}
          />
        </Flex>
        <Flex direction="column" align="center">
          <VStack alignItems="center" spacing={0}>
            <Accordion allowMultiple w="100vw">
              <AccordionItem py={3}>
                <AccordionButton>
                  <Box
                    textAlign="center"
                    fontSize={18}
                    fontWeight="bold"
                    w="100vw"
                    _hover={{ color: '#cf0032' }}
                  >
                    Web Curation
                  </Box>
                </AccordionButton>
                <AccordionPanel w="100vw" textAlign="center">
                  <Link
                    href={generatePath('/wc/enterprise')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                    >
                      For Enterprise
                    </Text>
                  </Link>
                  <Link
                    href={generatePath('/wc/platforms')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                    >
                      For Platforms
                    </Text>
                  </Link>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Link
              href={generatePath('/content-skills-mapper')}
              style={{ textDecoration: 'none' }}
            >
              <Text
                fontSize={18}
                fontWeight="bold"
                _hover={{ color: '#cf0032' }}
                style={{
                  textDecoration: 'none',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                Content to Skills Mapper
              </Text>
            </Link>
            <Divider />
            <Accordion allowMultiple w="100vw">
              <AccordionItem py={3}>
                <AccordionButton>
                  <Box
                    textAlign="center"
                    fontSize={18}
                    fontWeight="bold"
                    w="100vw"
                    _hover={{ color: '#cf0032' }}
                  >
                    Complete Curation
                  </Box>
                </AccordionButton>
                <AccordionPanel w="100vw" textAlign="center">
                  <Link
                    href={generatePath('/cc/enterprise')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                    >
                      For Enterprise
                    </Text>
                  </Link>
                  <Link
                    href={generatePath('/cc/platforms')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                    >
                      For Platforms
                    </Text>
                  </Link>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Accordion allowMultiple w="100vw">
              <AccordionItem py={3}>
                <AccordionButton>
                  <Box
                    textAlign="center"
                    fontSize={18}
                    fontWeight="bold"
                    w="100vw"
                    _hover={{ color: '#cf0032' }}
                  >
                    More
                  </Box>
                </AccordionButton>
                <AccordionPanel w="100vw" textAlign="center">
                  <Link
                    href={generatePath('/client-success-stories')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                    >
                      Success Stories
                    </Text>
                  </Link>
                  <Link
                    href={generatePath('/blog')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                    >
                      Blog
                    </Text>
                  </Link>
                  <Link
                    href={generatePath('/contact')}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      fontSize={18}
                      fontWeight="400"
                      _hover={{ color: '#cf0032' }}
                    >
                      Contact
                    </Text>
                  </Link>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <VStack pt={20}>
              <Link
                href={generatePath('/contact')}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  background="#cf0032"
                  color="white"
                  rounded={3}
                  _hover={{
                    color: 'white',
                    opacity: 0.7,
                  }}
                >
                  <Text fontSize={18} fontWeight="400">
                    Book a consultation
                  </Text>
                </Button>
              </Link>
              <Link
                href={
                  isLoggedIn
                    ? `${PREFIX}://app.anderspink.${DOMAIN}`
                    : `${PREFIX}://auth.anderspink.${DOMAIN}/login`
                }
                style={{ textDecoration: 'none' }}
              >
                <Button
                  background="white"
                  color="#cf0032"
                  borderWidth={1}
                  borderColor="#cf0032"
                  rounded={3}
                  _hover={{ color: 'black' }}
                >
                  <Text fontSize={18} fontWeight="400">
                    {isLoggedIn ? 'Go to Apps' : 'Log in'}
                  </Text>
                </Button>
              </Link>
            </VStack>
          </VStack>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavBar;
